<template>
  <div>
    <lp-datatable
      title="Prefund"
      store-module="prefund"
      store-data-endpoint="getAdminPrefundRecords"
      store-getter-endpoint="getPrefundRecordList"
      pagination-state-key="prefundRecordList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="transaction_invoice_no"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @actionBtn-clicked="actionBtnClicked"
      @aditBtn-clicked="editBtnClicked"
      @viewBtn-clicked="viewBtnClicked"
      @deleteBtn-clicked="deleteBtnClicked"
      @extraBtn-clicked="extraBtnClicked"
      @status-filter-btn-clicked="statusFilterBtnClicked"
    />

    <b-modal
      ref="payoutProofAdmin"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="Prefund Transaction"
    >
      <ValidationObserver
        ref="proofObserver"
        v-slot="{ handleSubmit, valid, errors }"
      >
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="2">
                <b-form-group
                  label="Account ID"
                >
                  <validation-provider
                    v-slot="{ valid, errors, required }"
                    name="account_id"
                    rules="required"
                  >

                    <b-form-input
                      v-model="proofForm.account_id"
                      trim
                      readonly
                    />
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Amount (MYR)"
                >
                  <validation-provider
                    v-slot="{ valid, errors, required }"
                    name="value"
                    rules="required"
                  >

                    <b-form-input
                      v-model="proofForm.value"
                      trim
                      readonly
                    />
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="TXN Invoice No"
                >
                  <validation-provider
                    v-slot="{ valid, errors, required }"
                    name="transaction_invoice_no"
                    rules="required"
                  >

                    <b-form-input
                      v-model="proofForm.transaction_invoice_no"
                      trim
                      readonly
                    />
                  </validation-provider>

                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Merchant Pool ID"
                >
                  <validation-provider
                    v-slot="{ valid, errors, required }"
                    name="merchant_pool_id"
                    rules="required"
                  >

                    <b-form-input
                      v-model="proofForm.merchant_pool_id"
                      trim
                      readonly
                    />
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Switch Pool ID"
                >
                  <validation-provider
                    v-slot="{ valid, errors, required }"
                    name="switch_pool_id"
                    rules="required"
                  >

                    <b-form-input
                      v-model="proofForm.switch_pool_id"
                      trim
                      readonly
                    />
                  </validation-provider>

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Leanx Transaction Slip"
                >
                  <validation-provider
                    v-slot="{ valid, errors, required }"
                    name="image_path"
                    rules="required"
                  >
                    <lp-base-file-upload
                      v-model="proofForm.image_path"
                      allowed-file-types="image/jpeg, image/png, image/gif, application/pdf"
                      form-var="image_path"
                      :selected-images="proofForm.image_path"
                      @image-selected="processForm('image_path', $event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col>
                <b-form-group
                  label="Last Updated"
                >
                  <validation-provider
                    v-slot="{ valid, errors, required }"
                    name="updated_at"
                    rules="required"
                  >

                    <b-form-input
                      :value="transformDate(proofForm.updated_at, false, 'FF' )"
                      trim
                      readonly
                    />
                  </validation-provider>

                </b-form-group>
                <b-form-group
                  label="Transaction Status"
                >
                  <validation-provider
                    v-slot="{ valid, errors, required }"
                    name="transaction_status"
                    rules="required"
                  >
                    <v-select
                      v-model="proofForm.transaction_status"
                      :reduce="record_status=>record_status.text"
                      label="text"
                      :options="transactionStatusOpts"
                    />
                  </validation-provider>

                </b-form-group>
                <b-form-group>
                  <validation-provider
                    v-slot="{ valid, errors, required }"
                    name="confirmation"
                    :rules="{ required: { allowFalse: false } }"
                  >
                    <b-form-checkbox
                      v-model="confirmCheckbox"
                      :value="true"
                      :unchecked-value="false"
                    >
                      I hereby confirm that I have validated this transaction to be true.
                    </b-form-checkbox>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-end p-1">
          <b-button
            class="mr-1"
            type="submit"
            variant="primary"
            @click="handleSubmit(onUpdate)"
          >
            Update
          </b-button>
          <b-button
            class=""
            type="reset"
            variant="secondary"
            @click="cancelForm"
          >
            Cancel
          </b-button>

        </div>
      </ValidationObserver>

      <!--      <pre>{{ proofForm }}</pre>-->
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import axios from 'axios'
import Config from '@/views/administration/prefund/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'

import LpBaseFileUpload from '@/leanpay-components/LPBaseFileUpload.vue'

export default {
  components: {
    LpDatatable, ValidationObserver, ValidationProvider, LpBaseFileUpload, vSelect,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      currentRowData: {},
      cardTitle: 'Prefund',
      dataGridEndpoint: [],
      module: 'prefund',
      confirmCheckbox: false,

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      proofForm: {},

      record_status: 1, // active only
      searchField: 'transaction_invoice_no',

      searchFieldOpts: [
        { value: 'transaction_invoice_no', text: 'Invoice No' },
      ],
    }
  },
  computed: {
    transactionStatusOpts() {
      return [
        { value: 1, text: 'PENDING' },
        { value: 2, text: 'COMPLETED' },
      ]
    },
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridData.recordsTotal
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      const x = this.tableConfig.tableHeader
      return x.filter(y => y.visible)
    },
    TableActions() {
      const x = this.tableConfig.tableAction
      return x.filter(y => y.enable)
    },
    sortObj() {
      const obj = {
        parameter_name: this.sortBy,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters[`${this.module}/getPrefundRecordList`]
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]

      console.log('---->', list)
      if (list !== null) {
        return {
          draw: list.draw,
          recordsTotal: list.record_total,
          next_page: list.next_page,
          next_page_length: list.next_page_length,
          next_page_start: list.next_page_start,
          previous_page_length: list.previous_page_length,
          previous_page_start: list.previous_page_start,
          previouse_page: list.previouse_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Prefund' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    this.initDataGrid()
  },
  methods: {
    uploadFiles() {
      const formData = new FormData()

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.files.length; i++) {
        // formData.append('file', this.files[i])
        formData.append('files', this.files[i])
      }
      this.$store.dispatch('mediaStore/uploadMerchantImageCdn', formData).then(res => {
        if (res.data.response_code === 2000) {
          console.log('data', res.data.data)
          this.filesUrl = res.data.data
        }
      })
    },
    onUpdate() {
      console.log(this.proofForm)
      const payload = {
        // ...this.proofForm,
        id: this.proofForm.id,
        transaction_status: this.proofForm.transaction_status,
        image_path: this.proofForm.image_path,
      }

      this.$store.dispatch('prefund/updateAdminPrefundRecords', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.initDataGrid()
          this.cancelForm()
        }
      })
    },
    cancelForm() {
      this.proofForm = {}
      this.confirmCheckbox = false
      this.$refs.payoutProofAdmin.hide()
    },
    processForm(field, $event) {
      if (field === 'image_path') {
        // eslint-disable-next-line prefer-destructuring
        console.log('proofForm imagePath', $event)
        this.proofForm[field] = $event
      } else {
        this.proofForm[field] = $event
      }
    },
    actionBtnClicked(e) {
      if (e.actionName === 'openUpdatePrefundStatus') {
        this.currentRowData = e.rowData
        this.proofForm = e.rowData
        this.$refs.payoutProofAdmin.show()
      }
      console.log(e)
    },
    deleteBtnClicked(e) {
      console.log(e)
    },
    viewBtnClicked(e) {
      console.log(e)
    },
    editBtnClicked(e) {
      console.log(e)
    },
    extraBtnClicked(e) {
      console.log(e)
    },
    statusFilterBtnClicked(e) {
      if (e === 'completedPrefundFilter') {
        console.log(this.tableConfig.tableAction)
        this.initDataGrid('SUCCESS')
        this.selectedStatus = { field: 'transaction_status', q: 'SUCCESS' }
      }
      if (e === 'pendingPrefundFilter') {
        this.initDataGrid('PENDING')
        this.selectedStatus = { field: 'transaction_status', q: 'PENDING' }
      }

      console.log(e)
    },
    pagination(e) {
      if (this.tableSettings.search) {
        const payload = {
          start: (e - 1) * this.tableSettings.perPage,
          length: this.tableSettings.perPage,
          sort: this.sortObj,
          search: this.searchTerm,
        }
        this.isBusy = true
        this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
          this.isBusy = false
        })
      }
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid(vuexEndpoint) {
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(vuexEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    initDataGrid(status = null) {
      console.log('plans')
      this.isBusy = true
      let payload = {
        limit: this.tableSettings.perPage,
        skip: 0,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
      }

      if (status !== null) {
        payload = {
          ...payload,
          search: {
            search_enable: true,
            search_key: status,
            search_column: 'transaction_status',
            search_replace_word_enable: false,
            search_word_replace: ' ',
            search_word_replace_to: '_',
          },
        }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.module}/getAdminPrefundRecords`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false

        this.searchTerm = ''
      })
    },
  },
}
</script>
