const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Download Template',
      //   buttonRef: 'downloadTemplateBtn',
      //   variant: 'info',
      // },
    ],
    tableStatusFilterButton: [
      {
        buttonLabel: 'Pending',
        buttonRef: 'pendingPrefundFilter',
        active: true,
      },
      {
        buttonLabel: 'Success',
        buttonRef: 'completedPrefundFilter',
        active: false,
      },
    ],
    buttons: {
      createButton: false,
      createButtonLabel: 'Create New Prefund',
      createButtonRoute: 'administration-prefund-new',
      refresh: true,
    },
    tableActionField: true,
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'value', text: 'Amount' },
    { value: 'transaction_invoice_no', text: 'Invoice No' },
    { value: 'switch_pool_id', text: 'Switch Pool ID' },
    { value: 'merchant_pool_id', text: 'Merchant Pool ID' },
    { value: 'transaction_status', text: 'Transaction Status' },
  ],
  tableData() {
    return {
      tableAction: [
        // CUSTOM
        // {
        //   enable: true, param: 'id', btnLabel: 'A', action: 'custom', btnIcon: '', btnOnly: false, variant: 'warning', size: 'sm', iconSize: '12', actionParam: 'openA',
        // },
        {
          enable: true, param: 'id', btnLabel: 'Update Status', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'openUpdatePrefundStatus', disableCondition: 'transaction_status:COMPLETED',
        },

        // DEFAULT
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'account_id', label: 'Account ID', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'name', label: 'Sender Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'transaction_invoice_no', label: 'Invoice No', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'switch_pool_id', label: 'Switch Pool ID', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'merchant_pool_id', label: 'Merchant Pool ID', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'value', label: 'Amount', sortable: true, visible: true, type: 'monetary',
        },
        {
          key: 'transaction_status', label: 'Transaction Status', sortable: true, visible: true,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: true, type: 'date',
        },
      ],
    }
  },
}

export default Config
